<template>
    <div>
        <back-header C_Title='correctOrder'></back-header>
        <el-tabs v-model="S_activeName" type="border-card" @tab-click="handleClick"
                 style="width: 80%;margin-left: 10%;margin-top:8rem">
            <el-tab-pane label="全部" name="first">
                <correct-table type="5" v-if="S_activeName=='first'"></correct-table>
            </el-tab-pane>
            <el-tab-pane label="待审核" name="second">
                <correct-table type="0" v-if="S_activeName=='second'"></correct-table>
            </el-tab-pane>
            <el-tab-pane label="待支付" name="third">
                <correct-table type="3" v-if="S_activeName=='third'"></correct-table>
            </el-tab-pane>
            <el-tab-pane label="已生效" name="fourth">
                <correct-table type="1" v-if="S_activeName=='fourth'"></correct-table>
            </el-tab-pane>
<!--            <el-tab-pane label="已取消" name="fifth">-->
<!--                <correct-table type="4" v-if="S_activeName=='fifth'"></correct-table>-->
<!--            </el-tab-pane>-->
<!--            <el-tab-pane label="已拒绝" name="sixth">-->
<!--                <correct-table type="2" v-if="S_activeName=='sixth'"></correct-table>-->
<!--            </el-tab-pane>-->
        </el-tabs>
    </div>
</template>

<script>
    import BackHeader from '../../components/back/backHeader'
    import correctTable from '../../components/back/correctTable'

    export default {
        name: "correctOrder",
        components: {BackHeader, correctTable},
        data() {
            return {
                S_activeName: 'first',//第几个目录
            }
        },
        methods: {
            handleClick(e) {
                console.log(e)
            }
        },


    }
</script>

<style scoped lang="scss">

</style>
