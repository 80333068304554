<template>
    <div class="table-box">
        <el-table
                :data="B_tableList"
                border
                style="">
            <el-table-column
                    prop="changeId"
                    label="批改申请ID"
                    min-width="140"
                    align="center">
            </el-table-column>
            <el-table-column
                    prop="num_id"
                    label="流水号"
                    min-width="140"
                    align="center">
            </el-table-column>
            <el-table-column
                    prop="policy_num"
                    label="保单号"
                    min-width="140"
                    align="center">
                    <template slot-scope="scope2">
                        <div>
                            <span>{{scope2.row.policy_num==""?"暂无":scope2.row.policy_num}}</span>
                        </div>
                    </template>
            </el-table-column>
            <el-table-column
                    prop="applicant_name"
                    label="投保人"
                    min-width="140"
                    align="center">
            </el-table-column>
            <el-table-column
                    prop="pro_name"
                    label="产品名称"
                    min-width="100"
                    align="center">
            </el-table-column><el-table-column
                    prop="company_name"
                    label="保险公司"
                    align="center">
            </el-table-column>
            <el-table-column
                    prop="c_time"
                    label="创建时间"
                    align="center">
            </el-table-column>
            <el-table-column
                    prop="money"
                    label="保费金额(元)" 
                    width="120"
                    align="center">
            </el-table-column>  
            <el-table-column
                    label="审核状态"
                    align="center"
                    width="120">
                <template slot-scope="scope">
                    <div>
                        <span>{{status[0][scope.row.check_status]}}</span>
                    </div>
                </template>
            </el-table-column>
<!--            <el-table-column-->
<!--                    prop="is_effect"-->
<!--                    label="生效状态"-->
<!--                    width="120"-->
<!--                    align="center">-->
<!--            </el-table-column>-->
            <el-table-column
                    label="操作"
                    min-width="150"
                    align="center">
                <template slot-scope="scope">
                    <el-button @click="viewOrder(scope.row.num_id,scope.row.check_status,scope.row.changeId)" type="text" size="small">查看</el-button>
                    <el-button v-if="scope.row.check_status==3" @click="goPay(scope.row.num_id,scope.row.pay_type,scope.row.pay_type,scope.row.other,scope.row.money,scope.row.changeId)" type="text" size="small">去支付</el-button>
<!--                    <el-button v-if="scope.row.check_status==3" @click="deleteOrder(scope.row.changeId)" type="text" size="small">取消批改</el-button>-->
                </template>
            </el-table-column>
        </el-table>
        <pay ref="payVisible"  :paytype="paytype" :payDis="payDis" :num_id='num_id' :company_bank_name='company_bank_name' :company_bank_no="company_bank_no" :company_name="company_name" :totalMoney="totalMoney" :change_id="change_id"></pay>
    </div>
</template>

<script>
import pay from "../../components/back/pay";
    export default {
        name: "correctTable",
        components: {
            pay
        },
        props:["type"],
        data() {
            return {
                status: [{
                        0:"待审核",
                        1:"已生效",
                        2:"已拒绝",
                        3:"待支付",
                        4:"已取消",
                    }],
                B_tableList: [],//数据
                change_id:'',//去支付使用
                paytype:'',//去支付使用 支付方式
                payDis:"",  //禁用哪种支付方式
                num_id:'',//去支付使用
                company_bank_name:"",//去支付使用开户行
                company_bank_no:'',//去支付使用账户
                company_name:"",//去支付使用账户名
                totalMoney:'',//去支付使用总保费
            }
        }, 
        mounted() {
            console.log(this.type)
             this.getChangeList(this.type)
        },
        methods: {
            // 加载批单数据
            getChangeList:function(type){
                let that=this;
                let loading=that.$loading({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                that.axios({
                    url: this.$store.state.baseUrl + '/api/order/orders/getChangeList',
                    method: 'POST',
                    params:{
                        authCheck:0,
                        source:4,
                        login_type:that.$store.state.login_type,
                        token:that.$store.state.logininformation.token,
                        uid:that.$store.state.logininformation.uid,
                        check_status:type
                    }
                }).then(function(res) {
                    console.log(res)
                    loading.close();
                    if(res.data.code==200){
                        that.B_tableList=res.data.data
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            // 去支付
            goPay:function(num_id,pay_type,payDis,other,policy_money,change_id){
                console.log(num_id)
                console.log(pay_type)
                console.log(payDis)
                console.log(other)
                console.log(policy_money)
                console.log(change_id)
                if(pay_type!=1){//去支付使用 支付方式
                    this.paytype='0'
                }else{
                    this.paytype='1'
                }
                this.payDis=payDis+'',  //禁用哪种支付方式
                this.num_id=num_id,//去支付使用
                this.change_id=change_id,//去支付使用
                this.company_bank_name=other.company_bank_name,//去支付使用开户行
                this.company_bank_no=other.company_bank_no,//去支付使用账户
                this.company_name=other.company_name,//去支付使用账户名
                this.totalMoney=policy_money,//去支付使用总保费
                this.$refs.payVisible.open()                   
            },
            //取消批改
            deleteOrder:function(e){
               let that=this;
                that.$confirm('您是否要取消批改?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    let loading=that.$loading({
                        lock: true,
                        text: 'Loading',
                        background: 'rgba(0, 0, 0, 0.5)'
                    });
                    that.axios({
                        url: that.$store.state.baseUrl + '/api/order/orders/cancelChange',
                        method: 'POST',
                        params:{
                            authCheck:0,
                            source:4,
                            login_type:that.$store.state.login_type,
                            token:that.$store.state.logininformation.token,
                            uid:that.$store.state.logininformation.uid,
                            change_id:e,
                        }
                    }).then(function(res) {
                        console.log(res)
                        loading.close();
                        that.getChangeList(that.type)              
                    }).catch(function (error) {
                        console.log(error);
                    });                    
                }).catch(() => {
                    that.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            // 查看订单
            viewOrder:function(num_id,check_status,changeId){
               this.$router.push({
                    name: 'correctLook',
                    query: {
                        num_id:num_id,
                        check_status:check_status,
                        change_id:changeId
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .policyState{
        color: dodgerblue;
    }
    
</style>
